import { inject, Injectable } from '@angular/core';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
} from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { AlertController, LoadingController, NavController, ToastController } from '@ionic/angular/standalone';
import { firstValueFrom, Observable } from 'rxjs';
import { firebaseError } from './translate';
import { HelperService } from '../lib/services/helper.service';
import { IDatabase } from '../../../mobile/src/config/constant';
import { mergeMap } from 'rxjs/operators';
import { environment } from '../../../customer/src/environments/environment';

declare type User = import('firebase/auth').User;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly afAuth = inject(Auth);
  private readonly alertCtrl = inject(AlertController);
  private readonly toastCtrl = inject(ToastController);
  private readonly navCtrl = inject(NavController);
  private readonly helper = inject(HelperService);
  private readonly loadingCtrl = inject(LoadingController);
  private readonly http = inject(HttpClient);

  constructor() {}

  /**
   * ログインできてるかどうかを確認
   * @param isReload メールのValidationチェック
   */
  public isAuth(isReload = false): Observable<'user' | 'confirm' | 'required'> {
    return authState(this.afAuth).pipe(
      mergeMap(async (data) => {
        if (isReload) {
          await this.afAuth.currentUser.reload();
        }
        if (data && data.emailVerified) {
          return 'user';
        } else if (data !== null) {
          return 'confirm';
        } else {
          return 'required';
        }
      }),
    );
  }

  public getState(): Observable<User | null> {
    return authState(this.afAuth);
  }

  public async signUp(email: string, password: string, database: IDatabase): Promise<void> {
    if (database) {
      localStorage.setItem('apiKey', database);
    }
    await createUserWithEmailAndPassword(this.afAuth, email, password).catch(async (error) => {
      await this.alertError(error);
      throw error;
    });
    await this.sendEmailVerification();
  }

  public async signIn(email: string, password: string, database: IDatabase): Promise<void> {
    if (database) {
      localStorage.setItem('apiKey', database);
    }
    await signInWithEmailAndPassword(this.afAuth, email, password).catch(async (error) => {
      await this.alertError(error);
      throw error;
    });
  }

  public async signOut(): Promise<void> {
    await this.afAuth.signOut().catch(async (error) => {
      await this.alertError(error);
      throw error;
    });
    await this.navCtrl.navigateRoot('/');
    await this.helper.presentToast({
      message: 'サインアウトしました',
    });
  }

  public async resetPassword(email: string): Promise<void> {
    await sendPasswordResetEmail(this.afAuth, email).catch(async (error) => {
      await this.alertError(error);
      throw error;
    });
    await this.helper.presentToast({
      message: 'パスワードを再設定する方法をメールアドレスに送信しました',
    });
  }

  public async sendEmailVerification(): Promise<void> {
    await sendEmailVerification(this.afAuth.currentUser).catch(async (error) => {
      await this.alertError(error);
      throw error;
    });
    await this.helper.presentToast({
      message: '認証URLつきのメールを送信しました',
    });
  }

  /**
   * メールアドレスの変更／追加
   * @param currentEmail 現在のメールアドレス
   * @param newEmail 新しいメールアドレス
   * @param isPassword Facebook認証かどうか
   */
  public async updateEmail(currentEmail: string, newEmail: string, isPassword: boolean): Promise<boolean> {
    if (!isPassword) {
      await this.helper.alertClose({
        header: `パスワードログインを有効化ください`,
        message: `メールアドレスを設定するには、パスワードが有効化されてる必要があります。先にパスワードログインを有効化してください。`,
      });
      return false;
    }

    const result = await new Promise<string>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: `現在のパスワード`,
        cssClass: 'alert-absolute',
        message: `メールアドレスの変更には、メール認証が必要です。メールアドレスを再度ご確認の上、変更する場合は現在のパスワードをご入力ください。`,
        inputs: [
          {
            name: 'password',
            placeholder: `パスワード`,
            type: 'text',
          },
        ],
        buttons: [
          {
            text: `キャンセル`,
            role: 'cancel',
            handler: () => resolve(null),
          },
          {
            text: `変更`,
            handler: (data) => resolve(data.password),
          },
        ],
      });
      alert.present();
    });

    if (!result) {
      return false;
    }
    return this.updateEmailMethod({ email: currentEmail, password: result }, newEmail).then(() => true);
  }

  /**
   * メールアドレスの追加
   * @param currentAuth 現在のAuth
   * @param newEmail 新しいメールアドレス
   */
  private async updateEmailMethod(currentAuth: { email: string; password: string }, newEmail: string) {
    const loading = await this.loadingCtrl.create({
      message: `保存中`,
    });
    loading.present().then();
    const credential = EmailAuthProvider.credential(currentAuth.email, currentAuth.password);
    const withCredential = await reauthenticateWithCredential(this.afAuth.currentUser, credential).catch(async () => {
      await this.helper.alertClose({
        header: `保存に失敗しました`,
        message: `現在のパスワードが間違っています`,
      });
      return undefined;
    });
    if (withCredential === undefined) {
      await loading.dismiss();
      return;
    }
    const updated = await updateEmail(this.afAuth.currentUser, newEmail).catch(async (e) => {
      this.alertError(e);
      return undefined;
    });
    if (updated === undefined) {
      await loading.dismiss();
      return;
    }
    await sendEmailVerification(this.afAuth.currentUser);
    await this.navCtrl.navigateRoot('/auth/confirm');
    loading.dismiss().then();
  }

  /**
   * パスワードの変更
   * @param currentEmail 現在のメールアドレス
   * @param newPassword 新しいパスワード
   */
  public async updatePassword(currentEmail: string, newPassword: string) {
    const alert = await this.alertCtrl.create({
      header: `使用中のパスワードをご入力ください`,
      cssClass: 'alert-absolute',
      inputs: [
        {
          name: 'password',
          placeholder: `パスワード`,
          type: 'password',
        },
      ],
      buttons: [
        {
          text: `キャンセル`,
          role: 'cancel',
        },
        {
          text: '変更',
          handler: async (data) => {
            const credential = EmailAuthProvider.credential(currentEmail, data.password);
            await reauthenticateWithCredential(this.afAuth.currentUser, credential).then(
              async () => {
                await updatePassword(this.afAuth.currentUser, newPassword).then(
                  async () => {
                    await this.helper.alertClose({
                      header: `保存しました`,
                      message: `パスワードを変更しました。以前のものは利用することはできませんのでご注意ください`,
                    });
                  },
                  (e) => this.alertError(e),
                );
              },
              async (error) => {
                await this.helper.alertClose({
                  header: `保存に失敗しました`,
                  message: `使用中のパスワードが間違っています`,
                });
              },
            );
          },
        },
      ],
    });
    alert.present();
  }

  private async alertError(e: { code: string; message: string }): Promise<void> {
    if (firebaseError.hasOwnProperty(e.code)) {
      e = firebaseError[e.code];
    }

    const alert = await this.alertCtrl.create({
      header: e.code,
      message: e.message,
      buttons: ['閉じる'],
    });
    await alert.present();
  }

  //有効なemailかどうかチェックする
  public async checkExistsEmail(email: string, database: IDatabase): Promise<boolean> {
    if (database) {
      localStorage.setItem('apiKey', database);
    }
    const checkResult = await firstValueFrom(this.http.get<void>(environment.api() + 'email_exists/' + email))
      .then((res) => {
        return true;
      })
      .catch(() => {
        return false;
      });
    if (checkResult == false) {
      const alert = await this.alertCtrl.create({
        header: 'メールアドレス',
        message: 'アカウントの事前登録が済んでいないので、管理者にご連絡ください',
        buttons: ['閉じる'],
      });
      await alert.present();
    }
    return checkResult;
  }
}
