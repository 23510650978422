export const firebaseError = {
  'auth/invalid-email': {
    code: 'メールアドレスが間違っています',
    message: 'メールアドレスのフォーマットが間違っています。ご確認ください。',
  },
  'auth/user-not-found': {
    code: 'ユーザが見つかりません',
    message: '入力いただいたユーザは存在しません。登録されていないメールアドレスか、すでに退会しています。',
  },
  'auth/wrong-password': {
    code: 'パスワードが間違っています',
    message: '入力いただいたパスワードが間違っているか、このユーザはFacebookログインを利用しており、パスワードログインを有効にしていません。',
  },
  'auth/weak-password': {
    code: '脆弱性があります',
    message: 'パスワードは最低でも6文字以上のものをご利用ください。大文字小文字数字が混在しているとセキュリティを高めることにつながります。',
  },
  'auth/email-already-in-use': {
    code: 'ユーザが存在しています',
    message: 'このメールアドレスを利用してすでにユーザが作成されています。ログイン画面に戻って、ログインください。',
  },
};
